import { ReactNode } from 'react';

import { EmailModalTypes } from 'lib/common/components/atoms/Email/EmailModal';

import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';

import UploadedFile from 'lib/common/types/UploadedFile';
import { TAttachment } from 'lib/common/types/email/TAttachment';

import ExceededSizeLimit from './components/ExceededSizeLimit';

// 20 MB
const TOTAL_EMAIL_SIZE_LIMIT_BYTES = 20971520;

// 5 MB (lambda payload max size is 6 MB)
const EMAIL_CONTENT_SIZE_LIMIT_BYTES = 5242880;

const MESSAGES = {
  UPLOADING_FILES:
    'There are still some files being uploaded. Please wait for the files to finish uploading, or cancel the upload.',
  EMPTY_SUBJECT: "You haven't entered a subject. Do you want to send anyway?"
};

export default function getPreSendModal({
  attachments,
  originalAttachments,
  subject,
  content
}: {
  attachments: Record<string, UploadedFile>;
  subject?: string;
  content: string;
  originalAttachments?: TAttachment[];
}): { type: EmailModalTypes; content: ReactNode } | null {
  const isUploadingFiles = Object.values(attachments).some(
    (uploadedFile) => uploadedFile.status === UploadedFileStatus.UPLOADING
  );

  if (isUploadingFiles) {
    return { type: EmailModalTypes.CANT_SEND, content: MESSAGES.UPLOADING_FILES };
  }

  const newAttachmentTotalSize = Object.values(attachments).reduce((acc, attachment) => {
    if (attachment.status !== UploadedFileStatus.COMPLETE) {
      return acc;
    }

    return acc + attachment.size;
  }, 0);

  const originalAttachmentTotalSize = (originalAttachments || []).reduce(
    (acc, attachment) => acc + attachment.content.byteLength,
    0
  );

  const contentTotalSize = new TextEncoder().encode(content).byteLength;

  const totalEmailSize = newAttachmentTotalSize + originalAttachmentTotalSize + contentTotalSize;

  if (totalEmailSize > TOTAL_EMAIL_SIZE_LIMIT_BYTES || contentTotalSize > EMAIL_CONTENT_SIZE_LIMIT_BYTES) {
    return {
      type: EmailModalTypes.CANT_SEND,
      content: (
        <ExceededSizeLimit
          totalEmailSize={totalEmailSize}
          contentTotalSize={contentTotalSize}
          originalAttachmentTotalSize={originalAttachmentTotalSize}
          newAttachmentTotalSize={newAttachmentTotalSize}
        />
      )
    };
  }

  if (!subject) {
    return { type: EmailModalTypes.WARNING, content: MESSAGES.EMPTY_SUBJECT };
  }

  return null;
}
