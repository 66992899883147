import { CSSProperties, ForwardedRef, ReactElement, forwardRef, useEffect, useRef, useState } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Button as MUIButton } from '@material-ui/core';
import cx from 'classnames';

import { ValueOf } from 'lib/core/types';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useActionState from 'lib/common/hooks/useActionState';
import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Icon from 'lib/common/components/Icon';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import './button.scss';

const BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

const TIME_BETWEEN_CLICKS_MS = 1000; // 1s

const ICON_SIZES = {
  [BUTTON_SIZES.SMALL]: 15,
  [BUTTON_SIZES.MEDIUM]: 17,
  [BUTTON_SIZES.LARGE]: 20
};

const STYLE_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DANGER: 'DANGER',
  SUCCESS: 'SUCCESS',
  NEUTRAL: 'NEUTRAL',
  WARNING: 'WARNING',
  SECONDARY_DANGER: 'SECONDARY_DANGER'
} as const;

const ICON_COLOUR_MAP = {
  [STYLE_TYPES.PRIMARY]: 'white',
  [STYLE_TYPES.SECONDARY]: 'primary',
  [STYLE_TYPES.DANGER]: 'white',
  [STYLE_TYPES.SUCCESS]: 'white',
  [STYLE_TYPES.WARNING]: 'white',
  [STYLE_TYPES.NEUTRAL]: void 0
};

const SPINNER_ICON = 'spinner-third';

export interface ButtonProps<T> {
  size?: ValueOf<typeof BUTTON_SIZES>;
  styleType?: ValueOf<typeof STYLE_TYPES>;
  busy?: boolean;
  icon?: IconDefinition;
  disabled?: boolean;
  children?: any;
  className?: string;
  tooltipContainerClassName?: string;
  onClick?: (arg0: unknown) => T | Promise<T>;
  onSuccess?: (response?: T) => void;
  onFailure?: (e: unknown) => void;
  onFinally?: () => void;
  endIcon?: IconDefinition;
  href?: string;
  style?: CSSProperties;
  type?: React.ComponentProps<typeof MUIButton>['type'];
  asyncAction?: boolean;
  round?: boolean;
  tooltip?: string;
  successTimeoutSeconds?: number;
  fullWidth?: boolean;
  preventDoubleClick?: boolean;
  noCapitalizedContent?: boolean;
  testId?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  onButtonDisabledChange?: (isButtonDisabled: boolean) => void;
}

function Button<T = unknown>(
  {
    size,
    styleType = STYLE_TYPES.PRIMARY,
    children,
    icon,
    className = '',
    tooltipContainerClassName = '',
    onClick,
    style,
    onFailure,
    onSuccess,
    onFinally,
    busy: loading = false,
    asyncAction,
    tooltip,
    round,
    successTimeoutSeconds,
    fullWidth = false,
    preventDoubleClick = false,
    disabled = false,
    noCapitalizedContent,
    testId,
    ariaLabel,
    autoFocus,
    onButtonDisabledChange,
    endIcon,
    ...props
  }: ButtonProps<T>,
  ref?: ForwardedRef<HTMLButtonElement>
) {
  const {
    icon: actionIcon,
    handleInteraction,
    busy
  } = useActionState<T>({
    onAction: onClick,
    onSuccess,
    onFailure,
    onFinally,
    loading,
    successTimeoutSeconds
  });

  const defaultRef = useRef<HTMLButtonElement>(null);

  // We were already incorrectly handling callback-style refs, this makes explicit
  // that we will not process them and cleans up the typing. If you are trying to pass
  // a callback ref to Button, you need to update this to set the callback with defaultRef.
  const usedRef = (typeof ref === 'function' ? undefined : ref) || defaultRef;

  const { isSoftphone: isSoftphoneLayout } = useLayout();
  const isSoftphoneQuery = useIsSoftphoneQuery();
  const isSoftphone = isSoftphoneLayout || isSoftphoneQuery;

  const defaultSize = isSoftphone ? BUTTON_SIZES.MEDIUM : BUTTON_SIZES.LARGE;

  const buttonSize = size || defaultSize;

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const prefixIcon = actionIcon ? actionIcon : icon;

  const iconSize = ICON_SIZES[buttonSize];

  useEffect(() => {
    setIsButtonDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    onButtonDisabledChange?.(isButtonDisabled);
  }, [isButtonDisabled]);

  useEffect(() => {
    if (disabled || !preventDoubleClick || !isButtonDisabled) {
      return;
    }

    const handler = setTimeout(() => {
      setIsButtonDisabled(false);

      // Move focus back to button, after action finished
      usedRef?.current?.focus();
    }, TIME_BETWEEN_CLICKS_MS);

    return () => clearTimeout(handler);
  }, [isButtonDisabled, preventDoubleClick]);

  const onButtonClick = (e) => {
    if (!onClick) {
      return;
    }

    if (asyncAction) {
      return handleInteraction();
    }

    onClick(e);
  };

  const onPreventDoubleClick = (e) => {
    if (isButtonDisabled) {
      return;
    }

    setIsButtonDisabled(true);

    return onButtonClick(e);
  };

  const iconOnly = !children && icon;

  const button = (
    <MUIButton
      data-testid={testId || `button-${styleType?.toLowerCase().replaceAll('_', '-')}`}
      className={cx(
        'button',
        `button--${buttonSize}`,
        {
          [`button--${styleType?.toLowerCase().split('_').join('-')}`]: !style,
          'button--busy': busy,
          'button--disabled': isButtonDisabled || busy,
          'button--icon-only': iconOnly,
          'button--round': round,
          'button--softphone': isSoftphone,
          'button--no-capitalized-content': noCapitalizedContent
        },
        className
      )}
      autoFocus={autoFocus}
      aria-disabled={isButtonDisabled || busy}
      disabled={isButtonDisabled || busy}
      style={style}
      fullWidth={fullWidth}
      endIcon={
        !busy && endIcon ? (
          <Icon icon={endIcon} className="button__icon" color={ICON_COLOUR_MAP[styleType]} size={iconSize} />
        ) : (
          void 0
        )
      }
      onClick={preventDoubleClick ? onPreventDoubleClick : onButtonClick}
      disableRipple
      ref={usedRef}
      classes={{
        focusVisible: `button--${styleType.toLowerCase()}`
      }}
      aria-label={ariaLabel || children?.toString() || tooltip}
      {...props}
    >
      {prefixIcon && (
        <>
          <span className="sr-only">Loading</span>
          <Icon
            className="button__icon"
            // busy used for error, loading and success states. Need to look at the icon name directly as only the loading icon should spin
            spin={prefixIcon.iconName === SPINNER_ICON}
            color={ICON_COLOUR_MAP[styleType]}
            icon={prefixIcon}
            size={iconSize}
          />
        </>
      )}
      {icon && actionIcon && <span className="button__icon-spacer" style={{ width: `${iconSize}px` }} />}
      {children && <div className="button__content">{children}</div>}
    </MUIButton>
  );

  return tooltip && !busy ? (
    <Tooltip enterDelay={1000} title={tooltip} ariaHidden>
      <div className={cx(tooltipContainerClassName)}>{button}</div>
    </Tooltip>
  ) : (
    button
  );
}

// https://oida.dev/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
// we need to pass generics to button to ensure onSuccess recieves the same type as onAction returns
export default forwardRef(Button) as <T>(
  props: ButtonProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof Button>;
